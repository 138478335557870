

































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';

@Component({
    components: {},
})

export default class HomeView extends ListBase {
    reports: Array<any> = [
        {
            title: 'Reportes de Agremiados',
            icon: 'school',
            items: [
                { title: 'Reporte de Agremiados', link: 'rquerylawyers' },
            ]
        }, {
            title: 'Reportes de Cursos',
            icon: 'school',
            items: [
                { title: 'Reporte de Cursos y Clases', link: 'rcourseclasess' },
                { title: 'Reporte de Cursos y Asistencias', link: 'rcourseattendances' }, 
            ]
        }, {
            title: 'Reportes de Mesa de Partes',
            icon: 'account_balance',
            items: [
                { title: 'Reporte de Solicitud 25%', link: 'rapplication25' },
            ]
        }, {
            title: 'Reportes de Elecciones',
            icon: 'stacked_line_chart',
            items: [
                { title: 'Reporte de IPS Bloqueadas', link: 'rblacklist' },
                { title: 'Reporte de Votaciones', link: 'rvotes' },
            ]
        }
    ]

    get loading() {
        return this.$store.state.dashboard.loading;
    };

    
    async mounted() {
    }

    async created() {

    }
}
